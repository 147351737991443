import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"
import "../styles/news.css"
import {monthNames, pad} from '../components/names'

class EventOverview extends React.Component {

  render() {
      const regex = /(<([^>]+)>)/ig;
      const { data } = this.props
      const { currentNewPage, numNewPages } = this.props.pageContext
      const isFirst = currentNewPage === 1
      const isLast = currentNewPage === numNewPages
      const prevPage = currentNewPage - 1 === 1 ? '/' : (currentNewPage - 1).toString()
      const nextPage = (currentNewPage + 1).toString()

return(  
  <>
    <SEO title="Events" />
    <section id="races">
        <h2 className="heading">Events</h2>
        <section className="blog--collection">
            {data.allDirectusNews.edges.map(edge => (
              <Link to={edge.node.url ? `/${pad(new Date(edge.node.posted_on.replace(/-/g, '/')).getFullYear())}/${pad(new Date(edge.node.posted_on.replace(/-/g, '/')).getMonth()+1)}/${edge.node.url}` : "/"} className="blog--article-block" key={edge.node.id}>
                <article>
                    <Img fluid={edge.node.placeholder_image.localFile.childImageSharp.fluid}/>
                    {edge.node.type === "event" && (
                      <span className="blog--event">{edge.node.type}</span>
                    )}
                    <p className="blog--article-time">{monthNames[new Date(edge.node.posted_on.replace(/-/g, '/')).getMonth()] + " " + new Date(edge.node.posted_on.replace(/-/g, '/')).getDate() + ", " + new Date(edge.node.posted_on.replace(/-/g, '/')).getFullYear()}</p>
                    <p dangerouslySetInnerHTML={{__html:edge.node.name.replace(regex, '').length > 130 ? edge.node.name.replace(regex, '').substring(0, 130).substr(0, Math.min(edge.node.name.replace(regex, '').substring(0, 130).length, edge.node.name.replace(regex, '').substring(0, 130).lastIndexOf(" "))) +" ..." : edge.node.name.replace(regex, '')}}></p>
                    <span className="blog--article-link">Læs om event</span>
                </article>
              </Link>
            ))}
        </section>
        <section className="pagination">
          {isFirst ? (<p className="previous">tilbage</p>) : (<Link to={`/blog/${prevPage}`} className="previous">tilbage</Link>)}
          <p className="number">{`${currentNewPage}/${numNewPages}`}</p>
          {isLast ? (<p className="next">næste</p>) : (<Link to={`/blog/${nextPage}`} className="next">næste</Link>)}
        </section>
    </section>
  </>
)}}

export default EventOverview

export const query = graphql`
  query eventListsaQuery($skip: Int!, $limit: Int!) {
    allDirectusNews(sort: {fields: posted_on, order: DESC}, filter: {status: {eq: "published"}, type: {eq: "event"}}, limit: $limit, skip: $skip) {
            edges {
              node {
                id
                catch_text
                text
                type
                posted_on
                name
                placeholder_image {
                  localFile {
                    childImageSharp {
                        fluid(quality: 50, maxWidth: 500) {
                          ...GatsbyImageSharpFluid_withWebp                
                        }
                      }
                    }
                }
                url
              }
            }
          }
        }
`
